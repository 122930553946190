import { MouseEventHandler, useMemo } from 'react';
import { ContainedButton } from '../../../components/buttons';
import { RecordTable } from '../../../components/tables';
import { TableColumn, TableRecord } from '../../../types/record-table';

import { OrderItemInfo } from './orderItemInfo';

interface DetailRowProps {
  orderItems: TableRecord[];
  orderType: string;
  onEditClick?: (cart_item_id: string) => () => void;
  onPdfClick?: (pdfPaths: string[]) => MouseEventHandler;
  editPage?: boolean;
}
export default function DetailRow({
  orderItems,
  orderType,
  onEditClick,
  onPdfClick,
  editPage
}: DetailRowProps) {
  const columns = useMemo<TableColumn[]>(() => {
    let innerColumns = [
      {
        columnKey: 'preview',
        header: 'Preview',
        onRender: (orderItem: TableRecord) => {
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="54px"
              width="100%"
              dangerouslySetInnerHTML={{ __html: orderItem.previewSvg }}
            ></svg>
          );
        },
        widthClassNames: ['w-[30%]', 'flex', 'justify-center']
      },
      {
        columnKey: 'title',
        header: 'Product / Product Details',
        onRender: (orderItem: TableRecord) => (
          <OrderItemInfo
            amazonProperties={
              orderType === 'amazon' ? orderItem.properties : null
            }
            title={orderItem.title}
            properties={orderType === 'shopify' ? orderItem.properties : []}
          />
        ),
        widthClassNames: ['w-[25%]', 'flex']
      },
      {
        columnKey: 'quantity',
        header: 'Quantity',
        onRender: (orderItem: TableRecord) => orderItem.quantity ?? 1,
        widthClassNames: ['w-[25%]', 'flex', 'justify-center']
      },
      {
        columnKey: 'modified',
        header: 'Edited',
        onRender: (orderItem: TableRecord) =>
          orderItem.modified ? 'Edited' : '',
        widthClassNames: ['w-[25%]', 'flex', 'justify-center']
      }
    ];

    if (!editPage) {
      innerColumns = innerColumns.concat([
        {
          columnKey: 'pdf preview',
          header: '',
          onRender: (orderItem: TableRecord) => {
            if (isLegacyItem(orderItem, orderType)) {
              return null;
            }

            const disabled = !orderItem.pdfPaths || !orderItem.pdfPaths.length;

            return (
              <ContainedButton
                disabled={disabled}
                onClick={onPdfClick?.(orderItem.pdfPaths)}
              >
                {disabled ? 'No PDFs Available' : 'Download PDFs'}
              </ContainedButton>
            );
          },
          widthClassNames: ['w-[25%]', 'flex', 'justify-center']
        },
        {
          columnKey: 'edit button',
          header: '',
          onRender: (orderItem: TableRecord) => {
            if (isLegacyItem(orderItem, orderType)) {
              return `Old Configurator Product`;
            }

            return (
              <ContainedButton
                onClick={onEditClick?.(orderItem.cart_item_id)}
                className="mr-4"
              >
                Edit
              </ContainedButton>
            );
          },
          widthClassNames: ['w-[25%]', 'flex', 'justify-end']
        }
      ]);
    }
    return innerColumns;
  }, [editPage, orderType, onPdfClick, onEditClick]);

  return (
    <RecordTable
      data={orderItems}
      onRowClick={orderItem => {
        if (isLegacyItem(orderItem, orderType)) {
          return () => {
            alert(
              `This product was purchased using the old configurator. Any modifications must be made with an Ops report and modifications to the product code.`
            );
          };
        }

        return onEditClick?.(orderItem.cart_item_id) ?? (() => {});
      }}
      idKey="cart_item_id"
      rowClassNames={[
        'card',
        'mb-[6px]',
        'flex',
        'cursor-pointer',
        'items-center',
        'align-middle'
      ]}
      columns={columns}
    />
  );
}

function isLegacyItem(item: TableRecord, orderType: string) {
  if (orderType == 'shopify') {
    return !!item.properties?.find(
      (prop: { name: string; value: string }) => prop.name === '_ProductCode'
    );
  }
  return false;
}
