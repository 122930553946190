import { useCallback, useState } from 'react';

interface OrderItemInfoProps {
  title: string;
  amazonProperties?: { [key: string]: string };
  properties?: {
    name: string;
    value: string;
  }[];
}
export const OrderItemInfo = ({
  title,
  properties = [],
  amazonProperties = {}
}: OrderItemInfoProps) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const toggleShowAll = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setShowAll(!showAll);
  };
  const allProperties = useCallback(() => {
    if (properties.length > 0) {
      return properties.map(property => {
        return (
          <div key={property.name}>{`${property.name}: ${property.value}`}</div>
        );
      });
    }
    if (amazonProperties !== null) {
      return Object.keys(amazonProperties).map((key: string) => {
        return <div key={key}>{`${key}: ${amazonProperties[key]}`}</div>;
      });
    }
  }, [properties, amazonProperties]);

  return (
    <div>
      {
        <div>
          <div key={'title'}>{title}</div>
          {showAll ? allProperties() : []}
          <div className="text-navy hover:underline" onClick={toggleShowAll}>
            {showAll ? '- less info' : '+ more info'}
          </div>
        </div>
      }
    </div>
  );
};
