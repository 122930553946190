import { useCallback, useEffect, useState } from 'react';

import {
  Order,
  AmazonOrder
} from '../../../../../admin-api/src/features/orders';

import { SearchBar } from '../../../components/search-bar';
import { RecordTable } from '../../../components/tables';
import { LoadingModalComponent } from '../../../components/modals';
import { useLocalStorage, useTRPCRequest } from '../../../hooks';
import { TableRecord } from '../../../types/record-table';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';

const cellStyles = 'w-full overflow-hidden text-ellipsis';
const headerStyles = ['overflow-hidden', 'text-ellipsis'];

export function OrderList() {
  const [searchTerm, setSearchTerm] = useLocalStorage<string>(
    window.location.pathname,
    ''
  );

  const [order, setOrder] = useState<Order | AmazonOrder>();
  const [orderType, setOrderType] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleTRPCRequest } = useTRPCRequest();

  const fetchOrder = useCallback(
    async (search?: string) => {
      if (!search) return;
      if (!search.includes('-')) {
        search = `#${search}`;
      }
      setIsLoading(true);
      const { res: order } = await handleTRPCRequest({
        method: TRPCMethodEnum.get,
        resourceType: TRPCResourceEnum.orders,
        requestBody: { orderIdentifier: search },
        options: {
          disableNavigateOnError: true
        }
      });
      setOrder(order);
      if (order?.order.shopify_order_name) {
        setOrderType('shopify');
      }
      if (order?.order.amazon_order_id) {
        setOrderType('amazon');
      }
      setIsLoading(false);
    },
    [handleTRPCRequest]
  );

  useEffect(() => {
    fetchOrder(searchTerm);
  }, [fetchOrder, searchTerm]);

  if (isLoading) {
    return <LoadingModalComponent isOpen={isLoading} />;
  }
  const noResults = !order;
  const noSearch = searchTerm === '';
  return (
    <>
      <div className="flex items-baseline gap-4">
        <span className="text-2xl font-semibold">Orders</span>
        <SearchBar
          searchTerm={searchTerm}
          onSearch={search => {
            setSearchTerm(search);
          }}
          classNames={['pb-2 grow']}
        />
      </div>
      {noResults && noSearch ? (
        <>
          <h2>Enter an order number to search.</h2>
        </>
      ) : noResults ? (
        <h2>No orders found for "{searchTerm}".</h2>
      ) : (
        <RecordTable
          data={[order]}
          idKey="order_id"
          rowClassNames={[
            'card',
            'mb-[6px]',
            'flex',
            'min-h-[54px]',
            'cursor-pointer',
            'items-center',
            'align-middle'
          ]}
          calculateRowHref={order =>
            `/orders/${encodeURIComponent(
              orderType === 'shopify'
                ? order.order.shopify_order_name
                : order.order.amazon_order_id
            )}`
          }
          columns={[
            {
              columnKey: 'shopify_order_name',
              header: 'Order #',
              headerClassNames: headerStyles,
              onRender: (order: TableRecord) => (
                <div className={cellStyles}>
                  {order?.order?.shopify_order_name ||
                    order?.order?.amazon_order_id}
                </div>
              ),
              widthClassNames:
                orderType === 'amazon'
                  ? ['w-[20%]', 'pl-4']
                  : ['w-[8%]', 'pl-4']
            },
            {
              columnKey: 'order_id',
              header: 'Order Date',
              headerClassNames: headerStyles,
              onRender: (order: TableRecord) => (
                <div className={cellStyles}>
                  {formatDate(order?.order?.created_at)}
                </div>
              ),
              widthClassNames: ['w-[18%]', 'pl-4']
            },
            {
              columnKey: 'full_name',
              header: 'Client Full Name',
              headerClassNames: headerStyles,
              onRender: (order: TableRecord) => (
                <div className={cellStyles}>
                  {[
                    order?.order?.customer?.first_name,
                    order?.order?.customer?.last_name
                  ]
                    .filter(n => n)
                    .join(' ')}
                </div>
              ),
              widthClassNames:
                orderType === 'amazon'
                  ? ['w-[16%]', 'pl-4']
                  : ['w-[22%]', 'pl-4']
            },
            {
              columnKey: 'client_email',
              header: 'Client Email',
              headerClassNames: headerStyles,
              onRender: (order: TableRecord) => (
                <div className={cellStyles}>
                  {order?.order?.customer?.email || 'Amazon Customer'}
                </div>
              ),
              widthClassNames: ['w-[20%]', 'pl-4']
            },
            {
              columnKey: 'product_count',
              header: 'Product Count',
              headerClassNames: headerStyles,
              onRender: (order: TableRecord) => (
                <div className={cellStyles}>
                  {console.log(order)}
                  {order?.lineItems
                    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
                    .map((i: any) => (i.quantity ?? 1) as number)
                    .reduce((a: number, b: number) => a + b, 0)}
                </div>
              ),
              widthClassNames:
                orderType === 'amazon'
                  ? ['w-[14%]', 'pl-4']
                  : ['w-[20%]', 'pl-4']
            },
            {
              columnKey: 'edited',
              header: 'Edited',
              headerClassNames: headerStyles,
              onRender: order => {
                const edited = order?.lineItems?.find(
                  (item: { modified: boolean }) => item.modified
                );

                return (
                  <div className={cellStyles}>{edited ? 'Edited' : ''}</div>
                );
              },
              widthClassNames: ['w-[5%]', 'pl-4']
            }
          ]}
        />
      )}
    </>
  );
}

function formatDate(dateString: string) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const time = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    hour12: true,
    minute: 'numeric'
  });

  return `${month}/${day}/${year} ${time}`;
}
