import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { LineItem } from '../../../../../admin-api/src/features/shared/types';
import { getProductCode } from '../../../../../configurator-ui/src/features/cart/utils';
import { Product } from '../../../../../configurator-ui/src/types';
import { CartErrors } from '../../../../../configurator-ui/src/utils/getCartErrors';
import { configuratorStore } from '../../../../../configurator-ui/src/store';

import { useTRPCRequest } from '../../../hooks';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';
import {
  LoadingModalComponent,
  UnsavedChangesModalComponent
} from '../../../components/modals';
import {
  ContainedButton,
  SaveButtonComponent
} from '../../../components/buttons';
import { TableRecord } from '../../../types/record-table';
import { SizeEnum } from '../../../types';

import { PdfGenerationButton } from '../components/PdfGenerationButton';
import { Item } from '../components/Item';
import DetailRow from '../components/DetailRow';

interface orderDetails {
  orderIdentifier: string;
  productName: string;
  customerFirstName: string;
  customerLastName: string;
}

export default function OrderDetailEdit() {
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState(false);
  const [orderDetails, setOrderDetails] = useState<orderDetails>();
  const [orderType, setOrderType] = useState<string>('shopify');
  const [lineItem, setLineItem] = useState<LineItem>();
  const [errors, setErrors] = useState<CartErrors | undefined>();
  const [unsavedChanges, setUnsavedChanges] = useState(true);
  const unsavedChangesBlocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      unsavedChanges && currentLocation.pathname !== nextLocation.pathname
  );

  const { handleTRPCRequest } = useTRPCRequest();

  const fetchOrder = useCallback(async () => {
    setIsLoading(true);
    const response = await handleTRPCRequest({
      method: TRPCMethodEnum.get,
      resourceType: TRPCResourceEnum.orders,
      requestBody: {
        orderIdentifier: params.orderIdentifier
      }
    });
    if (response.error) {
      setIsLoading(false);
      toast.error('Error loading shopify order.');
    }
    const { lineItems, order } = response.res;
    const lineItem = lineItems.find(
      (l: LineItem) => l.cart_item_id == params.cart_item_id
    );

    const orderDetails = {
      orderIdentifier: order.orderIdentifier,
      productName: lineItem?.title,
      customerFirstName: order.customer.first_name,
      customerLastName: order.customer.last_name
    };

    const orderType = params.orderIdentifier?.includes('-')
      ? 'amazon'
      : 'shopify';

    setOrderType(orderType);
    setOrderDetails(orderDetails);
    setLineItem(lineItem);
    setIsLoading(false);
  }, [params.orderIdentifier, params.cart_item_id, handleTRPCRequest]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    const storeState = configuratorStore.getState();
    const productTag = storeState.item.properties.productTag;
    const apiProduct = storeState.api.queries[`getProduct("${productTag}")`]
      ?.data as Product;
    const { error } = await handleTRPCRequest({
      method: TRPCMethodEnum.update,
      resourceType: TRPCResourceEnum.cartItems,
      options: {
        getCreatedAndUpdated: true
      },
      requestBody: {
        cart_item_id: params.cart_item_id,
        product_id: storeState.item.properties.productId,
        style_id: storeState.item.properties.style?.style_id,
        palette_id: storeState.item.properties.palette?.palette_id,
        font_id: storeState.item.properties.font?.font_id,
        icon_id: storeState.item.properties.icon?.icon_id,
        text_fields: Object.entries(storeState.item.properties.fields).map(
          ([key, val]) => ({
            text_field_id: key,
            value: val.value
          })
        ),
        label_slots: Object.entries(storeState.item.properties.slots).map(
          ([key, val]) => ({
            slot_id: key,
            label_id: val.labelId,
            label_variant_id: val.variantId,
            material_id: val.materialId
          })
        ),
        legacy_product_code: getProductCode(
          apiProduct,
          storeState.item.properties
        ), // not on amazon orders
        previewSvg:
          document.getElementById(
            `product-preview-${storeState.item.properties.productId}`
          )?.outerHTML || '' // not on amazon orders
      }
    });
    if (!error) {
      toast.success('Cart item saved successfully!');
    } else {
      toast.error('There was an error saving cart item.');
    }
    setIsSaving(false);
    setUnsavedChanges(false);
  }, [setIsSaving, handleTRPCRequest, params.cart_item_id]);

  const saveDisabled = useMemo(() => {
    const hasErrors = !!errors;
    const noUnsavedChanges = unsavedChanges === false;

    return hasErrors || noUnsavedChanges;
  }, [errors, unsavedChanges]);

  if (isLoading) {
    return <LoadingModalComponent isOpen={isLoading} />;
  }
  return (
    <>
      <div className="flex justify-between">
        {orderType === 'shopify' ? (
          <div>Shopify Order Number: {orderDetails?.orderIdentifier}</div>
        ) : (
          <div>Amazon Order ID: {params.orderIdentifier}</div>
        )}
        <div>
          {orderDetails?.productName &&
            `Product Name: ${orderDetails.productName}`}
        </div>
        <div>
          <PdfGenerationButton
            disabled={unsavedChanges}
            cartItemId={params.cart_item_id}
            className="flex-1"
          />
        </div>
      </div>
      <Item updateErrors={setErrors} updateModified={setUnsavedChanges} />
      <DetailRow
        orderItems={[lineItem as TableRecord]}
        orderType={orderType}
        editPage={true}
      />
      <div className="mt-10 flex gap-3">
        <ContainedButton
          className="flex-1"
          onClick={() => {
            navigate(
              `/orders/${encodeURIComponent(params.orderIdentifier ?? '')}`
            );
          }}
          size={SizeEnum.large}
          color="secondary"
          data-testid="cancel-button"
        >
          <span>Cancel</span>
        </ContainedButton>
        <SaveButtonComponent
          isSaving={isSaving}
          onSave={handleSave}
          disabled={saveDisabled}
        />
      </div>
      <UnsavedChangesModalComponent
        unsavedChanges={unsavedChanges}
        unsavedChangesBlocker={unsavedChangesBlocker}
      />
    </>
  );
}
